import { inject, observer } from 'mobx-react';
import React, {Component, Fragment} from 'react';
import { Container, Header, Segment } from 'semantic-ui-react';
import WindowUtil from '../../shop/utils/WindowUtil';

@inject('storeFactory')
@observer
export default class PageNotFoundScreen extends Component {

	render() {
		console.log("R. Page Not Found Screen. ");
		let pageType = "Shop";
		const pageNotFoundRef = this.props.storeFactory.NavigationStore.getContextualParam("missing");
		if(pageNotFoundRef){
			pageType = pageNotFoundRef.type;
		}
		return(
			<Container fluid className="pageNotFound">
				<Segment className="msg">
					<div className="msgBox">
						<div className="header">
							<h3>Oops! {pageType} not found</h3>
							<h1>
								<span>4</span>
								<span>0</span>
								<span>4</span>
							</h1>
						</div>
						<h2>we are sorry, but the {pageType.toLowerCase()} you are looking for might have been removed or is temporarily unavailable.</h2>
						<h2>Please check the {pageType.toLowerCase()} link <br/> {WindowUtil.URL}</h2>
					</div>
				</Segment>
			</Container>
		);
	}
}